import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './/app-routing.module';

import { AppComponent } from './app.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NavbarComponent } from './components/navbar/navbar.component';
import { BannerComponent } from './components/banner/banner.component';
import { ProductHomeComponent } from './components/product-home/product-home.component';
import { AboutUsComponent } from './components/about-us/about-us.component';
import { GalleryComponent } from './components/gallery/gallery.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { ProductsComponent } from './components/products/products.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SurronEbikeComponent } from './components/surron-ebike/surron-ebike.component';
import { IntenseComponent } from './components/intense/intense.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    BannerComponent,
    ProductHomeComponent,
    AboutUsComponent,
    GalleryComponent,
    ContactUsComponent,
    ProductsComponent,
    HomePageComponent,
    SurronEbikeComponent,
    IntenseComponent
  ],
  imports: [
    BrowserModule,
    NgbModule,
    AppRoutingModule 

  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
