import { NgModule }             from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
 
import { ProductsComponent } from './components/products/products.component';
import { HomePageComponent } from './components/home-page/home-page.component';
import { SurronEbikeComponent } from './components/surron-ebike/surron-ebike.component';
import { IntenseComponent } from './components/intense/intense.component';
 
export const routes: Routes = [
  {
    path: '',
    component: HomePageComponent
   }, 
  {
    path: 'surron',
    component: SurronEbikeComponent
  }, 
  {
    path: 'intense',
    component: IntenseComponent
  }  
];
 
@NgModule({
  imports: [ RouterModule.forRoot(routes) ],
  exports: [ RouterModule ]
})
export class AppRoutingModule {}