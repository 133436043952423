import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-intense',
  templateUrl: './intense.component.html',
  styleUrls: ['./intense.component.css']
})
export class IntenseComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
